import Vue from 'vue'
import Vuex from 'vuex'
import {CurrentWxStore} from './modules/wechat'
import { ChatStore } from './modules/chat'
import { ParamStore } from './modules/param'

Vue.use(Vuex)

export interface IRootState {
  wechat: CurrentWxStore;
  chat: ChatStore;
  param: ParamStore;
}

export default new Vuex.Store<IRootState>({})